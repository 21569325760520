import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './assets/css/fonts.css'
import { Layout } from 'antd'
import { Header } from './components/Header'
import { Menu } from './components/Menu'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { filter, head, prop, propEq } from 'ramda'
import { MarineLifeContent } from './components/MarineLifeContent'
import { MarineTypeContent } from './components/MarineTypeContent'
import './App.css'
import { Page } from './components/Page'
import { basePath } from './lib/api'
import {Contact} from "./components/Contact";

const { Content } = Layout
const contentStyle = {
  minHeight: 'initial',
  backgroundColor: 'white'
}
const layoutStyle = {
  overflow: 'hidden',
  width: 'calc(100%)',
  height: '100vh',
  maxWidth: 'calc(100%)',
}

const App = () => {

  const loadMarineLife = useStoreActions( prop( 'loadMarineLife' ) )
  const loadMedia = useStoreActions( prop( 'loadMedia' ) )
  const loadMarineType = useStoreActions( prop( 'loadMarineType' ) )
  const loadPages = useStoreActions( prop( 'loadPages' ) )

  const pages = useStoreState( prop( 'pages' ) )
  const thePage = head( filter( propEq( 'title', 'slug' ), pages ) )

  useEffect( () => {
    loadMarineLife()
    loadMedia()
    loadMarineType()
    loadPages()
  }, [loadMarineLife, loadMedia, loadMarineType, loadPages] )

  return <Layout style={layoutStyle}>
    <BrowserRouter>
      <Menu width={'25%'}/>
      <Layout style={{ overflowY: 'scroll' }}>
        <Header/>
        <Content style={contentStyle}>
          <Routes>
            <Route path={basePath + '/'} element={<Page page={thePage}/>}/>
            <Route path={basePath + '/:slug'} element={<Page/>}/>
            <Route path={basePath + '/marine-type/:type'} element={<MarineTypeContent/>}/>
            <Route path={basePath + '/marine-life/:type'} element={<MarineLifeContent/>}/>
            <Route path={basePath + '/contact'} element={<Contact/>}/>
          </Routes>
        </Content>
      </Layout>
    </BrowserRouter>
  </Layout>
}
export default App
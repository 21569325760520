import {invoker, length, tap} from 'ramda'
import axios from 'axios'

const siteRoot = global.siteRoot || 'http://ann-marine.local'
const asJson = invoker( 0, 'json' )
const emptyResponse = { json: () => [] }
export const canEditPosts = global.canEditPosts

export const get = (route = '') => {
  return fetch( siteRoot + '/wp-json' + route )
    .then( response => response.ok ? response : emptyResponse )
    .then( asJson )
}

export const post = (route = '', data) => {
  return axios.post(siteRoot + '/wp-json' + route , data)
      .then( response => response.status == 200 ? response : emptyResponse )
      .catch(d => d)
}

export const siteInfo = get

const trailingSlashRegx = /\/+$/
export const basePath =  (new URL( siteRoot )).pathname.replace(trailingSlashRegx, '')

export const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const paginatedLoad = async (url, action, page = 1) => {
  const data = await get(url + '?per_page=100&page=' + page)
  if (length(data) !== 0) {
    action(data)
    await paginatedLoad(url, action, page + 1)
  }

}

export const getPostEditLink = id => siteRoot + `/wp-admin/post.php?post=${id}&action=edit`
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Space, Spin} from 'antd';
import {useStoreActions, useStoreState} from "easy-peasy";
import {assoc, prop, propOr} from "ramda";
import {post} from "../lib/api";

export const Contact = () => {

    const nonce = useStoreState(prop('contactNonce'))
    const loadNonce = useStoreActions(prop('loadContactNonce'))
    const [sent, setSent] = useState(false)
    const [failed, setFailed] = useState(false)
    const [sending, setSending] = useState(false)

    const onFinish = async values => {
        setSending(true)
        setFailed(false)
        const result = await post('/v2/new-contact', assoc('nonce', nonce, values))
        const saved = propOr(false, 'saved', result.data)
        setSent(saved)
        setFailed(!saved)
        setSending(false)
    };
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    useEffect(() => {
        loadNonce()
    }, []);

    const titleStyle = {
        color: 'Black', fontSize: '18px',
    };

    return <>
        <Row style={{maxWidth: '600px'}}>
            <Col offset={8}>
                <h1 style={titleStyle}>CONTACT US</h1>
            </Col>
        </Row>
        <Row style={{maxWidth: '600px', marginBottom: '20px'}}>
            <Col offset={8}>
                This site is a work in progress. It is the intent to keep updating the content based on further diving
                and/or
                contributions from other people or organisations. Comments, corrections and new contributions are
                welcome by filling out this contact form or emailing directly to <a href="mailto:abrolhosmlg@gmail.com">abrolhosmlg@gmail.com</a>
            </Col>
        </Row>
        {!sent && <Spin spinning={sending}>
            <Form
                name="basic"
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{required: true, message: 'Please input your name'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{required: true, type: 'email', message: 'Please input your email'}]}
                >
                    <Input/>
                </Form.Item>

                <Form.Item
                    label="Message"
                    name="message"
                    rules={[{required: true, message: 'Please input a message or comment'}]}
                >
                    <Input.TextArea rows={4}/>
                </Form.Item>

                <Form.Item label={" "}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
        }
        {sent && <Row style={{maxWidth: '600px'}}>
            <Col offset={8}>
                Thanks for contacting us! We will be in touch with you shortly.
            </Col>
        </Row>
        }
        {failed && <Row style={{maxWidth: '600px'}}>
            <Col offset={8}>
                <span style={{color: 'red'}}>Something went wrong. Please try again.</span>
            </Col>
        </Row>
        }
    </>;

}


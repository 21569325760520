import { useStoreActions, useStoreState } from 'easy-peasy'
import { prop, filter, propEq, head, assoc } from 'ramda'
import { Link } from 'react-router-dom'
import {basePath} from "../lib/api";
import {textColor} from "../lib/settings";

export const ContactMenu = ({ name }) => {

  const setDisplay = useStoreActions( prop( 'setDisplay' ) )
  const display = useStoreState( prop( 'display' ) )

  const color = '#ffffff'

  const style = {
    color: textColor,
    height: 'calc(100%)',
    width: '100%',
    padding: '5px',
    borderLeftWidth: '6px',
    borderLeftStyle: 'solid',
    backgroundImage: 'linear-gradient(to left, ' + color + '90, ' + color + ')',
    borderColor: 'white'
  }

  const link = '/contact'

  return <Link
    to={basePath + link}
    className={'marine-group'}
    style={style}
  >
    <div>
      <b>
        {'CONTACT US'}
      </b>
    </div>
  </Link>
}
import {useEffect, useState} from 'react'
import {List} from 'antd'
import {fetchIt, getChildren, getColor, getSubGroups, removeChildren} from '../lib/marine-type'
import {MarineGroup} from './MarineGroup'
import {assoc, prop, propOr, tap} from 'ramda'
import {MarineSubGroupList} from './MarineSubGroupList'
import {PageMenu} from './PageMenu'
import {useStoreActions} from 'easy-peasy'
import {Search} from './Search'
import {SearchResults} from './SearchResults'
import {MarineLifeList} from "./MarineLifeList";
import {ContactMenu} from "./ContactMenu";

export const MarineTypesList = () => {

    const [types, setTypes] = useState([])
    const [allTypes, setAllTypes] = useState([])
    const [openItems, setOpenItems] = useState({})

    const setDisplay = useStoreActions(prop('setDisplay'))

    useEffect(() => {
        fetchIt().then(tap(setAllTypes)).then(getSubGroups).then(setTypes)
    }, [])

    const onSelect = (item, state) => {
        setTypes((state ? getChildren : removeChildren)(item, types, allTypes))
        setDisplay({type: 'marine-type', id: item})
        setOpenItems(assoc(item.id, state, openItems))
    }

    return <>
        <List>
            <List.Item style={{padding: '0px'}}>
                <PageMenu name={'title'}/>
            </List.Item>
            <List.Item style={{padding: '0px'}}>
                <PageMenu name={'acknowledgements'}/>
            </List.Item>
            <List.Item style={{padding: '0px'}}>
                <PageMenu name={'diversity'}/>
            </List.Item>
        </List>
        <List>
            <List.Item style={{padding: '0px'}}>
                <Search/>
            </List.Item>
        </List>
        <SearchResults/>
        <List
            dataSource={types}
            renderItem={(item) => {

                const color = getColor(item)

                return <>
                    <List.Item style={{padding: '0px'}}>
                        <MarineGroup item={item} onSelect={onSelect}/>
                    </List.Item>
                    {item.children && <MarineSubGroupList parent={item} items={item.children} color={color}/>}
                    {propOr(false, item.id, openItems) && <MarineLifeList marineType={item.id} color={color}/>
                    }
                </>
            }}
            size={'large'}
        />
        <List>
            <List.Item style={{padding: '0px'}}>
                <PageMenu name={'bibliography'}/>
            </List.Item>
            <List.Item style={{padding: '0px'}}>
                <ContactMenu/>
            </List.Item>
        </List>

    </>

};